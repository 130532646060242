<template>
  <div>
    <div class="loading">
      <div class="loading_box">
        <div><i class="el-icon-loading"></i>
          <div class="text">亚马逊授权中，请耐心等待</div>
        </div>
        <div class="btn">
          <el-button type="primary" size="small" round @click="clickBTn">授权完成</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {

  },
  mounted() {

  },
  methods: {
    clickBTn() {
      this.$emit('loadingShow')
    }
  }
}
</script>

<style scoped lang="less">
.loading {
  width: 100%;
  height: 300px;

  .loading_box {
    padding-top: 40px;
    text-align: center;

    .el-icon-loading {
      font-size: 50px;
      color: #f5222d;
    }

    .text {
      font-size: 16px;
      margin-top: 20px;
    }

    .btn {
      margin-top: 50px;
    }
  }

}
</style>

<template>
  <div v-cloak>
    <div class="shopTitle-box">
      <!-- 头部 -->
      <el-row class="shopTitle" :gutter="20">
        <!--                <el-col :span="1" >-->
        <!--                <el-checkbox class="checked"  :indeterminate="isIndeterminate" v-model="shopTitle" @change="handleCheckAllChange"></el-checkbox>-->
        <!--                </el-col>-->
        <el-col :span="8" style="padding-left: 20px">店铺名称</el-col>
<!--        <el-col :span="4">站点</el-col>-->

<!--        <el-col :span="3">自动同步订单</el-col>-->
<!--        <el-col :span="3">自动同步单号</el-col>-->
<!--        <el-col :span="5">创建人账号</el-col>-->
<!--        <el-col :span="2">状态</el-col>-->
        <!--                 <el-col :span="3">绑定物流渠道</el-col>-->
        <el-col :span="12">创建时间</el-col>
        <el-col :span="4">操作</el-col>
      </el-row>
      <!-- 列表 -->
      <div class="lodaing Listbox">
        <!-- 有数据 -->
        <div>
          <el-row class="shopList top-10px" v-for="(item,index) in shopsList" :key="item.id" :gutter="20">
<!--                        <div class="Disable" v-show="item.is_open===0">已禁用</div>-->
<!--            <div class="top-10px" :class="{'opacityShow':item.is_open === 0}">-->
              <!--                            <el-col :span="1" >-->
              <!--                                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">-->
              <!--                                        <el-checkbox class="checked" :label="item.id"></el-checkbox>-->
              <!--                                    </el-checkbox-group>-->
              <!--                            </el-col>-->
              <el-col :span="8" style="padding-left: 20px" class="storeName ">{{ item.name }}</el-col>
<!--              <el-col :span="4">-->
<!--                <span class="mr" v-for="(val,index) in country(item.amazon_site_country)" :key="index">{{ val }}</span>-->
<!--              </el-col>-->
<!--              <el-col :span="3" class="center">{{ item.is_autosync_order_word }}</el-col>-->
<!--&lt;!&ndash;              <el-col :span="3" class="center">{{ item.is_autosync_waybill_word }}</el-col>&ndash;&gt;-->
<!--              <el-col :span="5">-->
<!--                <div>账号：<span class="gray">{{ item.user ? item.user.phone : '暂无用户信息' }}</span></div>-->
<!--                &lt;!&ndash;                            <div>其他关联账号：<span class="gray">{{item.user.phone}}</span></div>&ndash;&gt;-->
<!--              </el-col>-->
<!--              <el-col :span="2">-->
<!--                <span>{{ item.is_authorize_word }}</span>-->
<!--                <div v-if="item.is_authorize_word === '未授权'" class="authorize" @click="skipAmazon(item)">立即授权</div>-->
<!--                <div v-else class="authorize" @click="skipAmazon(item)">重新授权</div>-->
<!--              </el-col>-->
              <el-col :span="12">{{ item.created_at }}</el-col>
              <el-col :span="4">
                <div class="colorOrange">
<!--                  <div @click="authorizationShow(item.id)" v-if="item.is_open === 1">编辑</div>-->
<!--                  &lt;!&ndash;                                 <div @click="relevanceIdIsShow">关联账号</div>&ndash;&gt;-->
<!--                  <div @click="transportShow(item)" v-if="item.is_open === 1">绑定物流渠道</div>-->
<!--                  <div @click="togglesEnable(item.id)" v-if="item.is_open === 1" class="active">禁用</div>-->
                  <div @click="disabledStore(item)">{{Number(item.is_open) === 1 ? '禁用' : '启用'}}</div>
<!--                  <div @click="togglesRemove(item.id,index)" v-if="item.is_open === 0">启用</div>-->
<!--                  <div @click="remove(item.id)" v-if="item.is_open === 0" class="active">删除</div>-->
                </div>
              </el-col>
<!--            </div>-->
          </el-row>
          <!-- 分页 -->
          <my-gallery-foot v-if="shopsList.length" :meta='meta' @currentPage='currentPage'/>
        </div>
        <!-- 无数据 -->
        <div class="footer_box" v-if="shopsListLength">
          <div class="storeImg">
            <!-- <i class="el-icon-shopping-cart-full"></i> -->
          </div>
          <!-- <div class="bg"></div> -->
          <div class="prompt">暂时还没有店铺，快来添加吧！</div>
          <button class="addStore" @click="addStore"><i class="el-icon-circle-plus-outline"></i> 添加店铺</button>
        </div>
      </div>
    </div>
    <!-- 物流渠道 -->
    <transport
        :transportIsShow='transportIsShow'
        :defaultLogistics="defaultLogistics"
        @close='transportClose'
        @update="getMyPlatformList"
        :logisticsId="logisticsId"
    />
    <!-- 弹框 -->
    <el-dialog
        :title="title"
        :visible.sync="authorizationIsShow"
        width="730px"
        :before-close="handleClose">
      <!-- //编辑授权弹窗 -->
      <compile-and-authorization
          v-if="authorizationIsShow"
          :shopsList='shopsList'
          @close='authorizationIsShow=$event'
          :shopsId='shopsId'
          @update='getUpdate'
      />
    </el-dialog>


    <!-- 关联账号 -->
    <relevance-id
        :relevanceIdShow='relevanceIdShow'
        @close='relevanceIdShow=$event'
    />

    <!--      授权弹窗-->
    <amazon-licensing
        :shopsList="shopsList"
        @update="authorizeUpdate"
        :itemId="itemId"
        v-model="amazonLicensingShow"
        :amazonItem="amazonItem"
        :allCountry="allCountry"
    />
  </div>
</template>

<script>
//删除店铺  禁用  启用
import {getDEleteShop, getDisableShop, getEnableShop} from '@/api/shops';
//获取店铺列表
import {getMyPlatformList} from '@/api/shops';
//编辑授权
import CompileAndAuthorization from './compileAndAuthorization.vue'
//关联账号弹窗
import RelevanceId from './relevanceId.vue'
// 物流渠道
import transport from './transport.vue'
// 分页
import MyGalleryFoot from '@/components/publicTab/index';
import {timestamp} from "@/utils/timejs";

import AmazonLicensing from "./amazonLicensing";
import {ableStore, storeIndex} from "../../../../../api/shops";

export default {
  components: {AmazonLicensing, transport, CompileAndAuthorization, RelevanceId, MyGalleryFoot},
  props: {
    //   查询数组
    formInline: {
      type: Object,
      required: false
    },
    //   站点
    countrys: {
      type: Array,
      required: false
    },
    //全部国家
    allCountry: {
      type: Array,
      required: false
    }
  },
  data() {

    return {
      amazonLicensingShow: false,//亚马逊授权弹窗
      shopsListLength: false,
      shopsId: null,  // 获取详情
      toggles: false, //切换禁用启用
      shopTitle: false, // 表头复选框
      title: '编辑店铺',
      checkedCities: [], //复选框
      transportIsShow: false, //物流渠道弹框
      authorizationIsShow: false, //编辑授权弹窗
      relevanceIdShow: false, //关联账号弹窗
      isIndeterminate: false,// 表头全选
      perPage: 5, //每页显示条数
      page: 1, //当前页
      shopsList: [], //店铺列表
      meta: {}, //分页信息
      amazonItem: {},
      logisticsId: null,
      defaultLogistics: null,
      itemId: null

    }
  },
  created() {

  },
  mounted() {
    //   开启loading
    this.openloading();
    this.getMyPlatformList()
    // 关闭loading
    setTimeout(() => {
      this.loading.close();
    }, 500)

  },
  methods: {


    //禁用店铺（新版）
    disabledStore(item){
      console.log(item)
      this.$confirm('此操作将禁用该店铺, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
      const { message } = await ableStore({
        storeCode:item.code
      })
        this.$message.success(message)
        this.getMyPlatformList()
      }).catch((e) => {
        if(e !== 'cancel') {
          this.$message.error(e)
        }
      });
    },
    //亚马逊授权
    async skipAmazon(item) {
      this.itemId = item.id
      this.amazonItem = item
      this.amazonLicensingShow = true

    },
    //关闭物流弹窗
    transportClose() {
      this.transportIsShow = false
      this.defaultLogistics = null
    },
    async authorizeUpdate(){
      await this.getMyPlatformList()
      await this.shopsList.forEach(item => {
        if (item.id === this.itemId) {
          this.$message.warning(item.is_authorize_word)
        }
      })
    },
    //更新数据
    async getUpdate() {
      await this.getMyPlatformList()
    },
    //   关闭编辑弹窗
    handleClose() {
      this.authorizationIsShow = false
    },
    //物流渠道弹框
    transportShow(item) {
      this.logisticsId = item.id
      this.defaultLogistics = item.default_logistics
      this.transportIsShow = true
    },
    //编辑弹窗
    async authorizationShow(id) {
      this.authorizationIsShow = true
      this.shopsId = id
    },
    //关联账号弹窗
    relevanceIdIsShow() {
      this.relevanceIdShow = true
    },
    // 表头tile复选框
    handleCheckAllChange(val) {
      let arr = []
      this.shopsList.forEach(item => {
        arr.push(item.id)
      })
      this.checkedCities = val ? arr : []
      this.isIndeterminate = false;
    },
    // 单选框
    handleCheckedCitiesChange(val) {
      let arr = []
      this.shopsList.forEach(item => {
        arr.push(item.id)
      })
      let checkedCount = val.length;

      this.shopTitle = checkedCount === arr.length

      this.isIndeterminate = checkedCount > 0 && checkedCount < arr.length
    },
    //   添加店铺
    addStore() {
      this.$emit('addStore')
    },
    //   删除
    remove(id) {
      try {
        this.$confirm('此操作将永久删除此店铺, 是否继续?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {

          let deleteId = JSON.stringify(id)

          const data = await getDEleteShop({storeIds: deleteId})

          this.getMyPlatformList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      } catch (error) {

        this.$message.error(error)
      }

    },
    //启用
    async togglesRemove(id, index) {

      try {
        this.$confirm('确定启用此店铺, 是否继续?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.toggles = false
          let storeIds = JSON.stringify(id)

          const data = await getEnableShop({storeIds: storeIds})

          this.getMyPlatformList()
          this.$message({
            type: 'success',
            message: '启用成功!'
          });
        })

      } catch (error) {

        this.$message.error(error)
      }


    },
    // 禁用
    async togglesEnable(id) {
      try {
        this.$confirm('确定禁用此店铺, 是否继续?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.toggles = true
          let storeIds = JSON.stringify(id)

          const data = await getDisableShop({storeIds: storeIds})

          await this.getMyPlatformList()
          this.$message({
            type: 'success',
            message: '已禁用!'
          });
        })

      } catch (error) {
        this.$message.error(error)
      }

    },
    //   获取店铺列表
    async getMyPlatformList() {

      try {
        // const {data} = await getMyPlatformList({
        //   perPage: this.perPage,
        //   page: this.page,
        //   keywordOrCode: this.formInline.keywordOrCode || undefined,
        //   status: this.formInline.state === 0 ? this.formInline.state : this.formInline.state || undefined,
        //   siteCountryId: this.formInline.site || undefined,
        //   startTime: timestamp(this.formInline.time[0]) || undefined,
        //   endTime: timestamp(this.formInline.time[1]) || undefined,
        //   userCode: this.formInline.user || undefined
        // })
        // this.shopsList = data.list
        // this.shopsListLength = this.shopsList.length === 0
        //
        // //分页
        // this.meta = data.meta

        const { data } = await storeIndex()
        console.log(data)
        this.shopsList = data
      } catch (error) {
        //  const {msg} = error
        this.$message.error(error)
      }

    },
    getSerach() {
      this.perPage = 10
      this.page = 1
      this.getMyPlatformList()
    },
    //   分页自定义事件
    currentPage(event) {
      this.page = event
      this.getMyPlatformList()
    },
    //   筛选站点
    country(amazon_site_country) {
      let countryArr = amazon_site_country.map(Number)
      let name = []
      this.allCountry.forEach(item => {
        countryArr.forEach(itm => {

          if (item.id === itm) {
            name.push(item.name_zh)
          }
        })


      })
      return name
    },
    // loading
    openloading() {
      this.loading = this.$loading({
        lock: true,
        text: "拼命加载中....",
        spinner: "el-icon-loading",
        background: "#fff",
        target: document.querySelector(".lodaing") // 指定区域
      });
    },
  },
  computed: {},
  watch: {
    checkedCities() {
      if (this.checkedCities.length > 0) {
        this.$emit('active')
      } else if (this.checkedCities.length === 0) {
        this.$emit('NOactive')
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  margin-top: 5vh !important;
}

/deep/ .el-dialog {
  //width: 730px !important;
  // height: 797px;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/deep/ .el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: #3A3A3A;
  line-height: 25px;
}

/deep/ .el-dialog__header {
  border-bottom: 1px solid #D8D8D8;
}

[v-cloak] {

  display: none;

}

.shopTitle-box {
  // width: 1650px;
  height: 50px;
  background: #F7F7F7;
  margin-top: 15px;

  .shopTitle {
    line-height: 50px;
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    // text-align: center;
  }

  .checked {
    margin-left: 10px;
  }

  .shopList {
    position: relative;

    margin: 1px 0 0 0 !important;
    font-size: 12px;
    font-weight: 400;
    color: #424242;
    line-height: 20px;
    background: #FFFFFF;
    padding-bottom: 10px;

    .Disable {
      font-weight: 600;
      color: #e8e8e8;
      position: absolute;
      left: 43%;
      top: 10px;
      width: 80px;
      height: 40px;
      border: 1px dashed #e8e8e8;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      transform: rotate(-20deg);
      // display: none;
    }

    //&:hover {
    //     box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.29);
    //}
  }

  .hide {
    display: block;
  }

  .top-10px {
    margin-top: 25px !important;
  }

  .gray {
    color: #C2C2C2;
  }

  .colorOrange {
    & > div:hover {
      color: #FA4A14;
      cursor: pointer
    }

    .active {
      color: #FA4A14;
    }

    & > span {
      margin-right: 10px;
    }
  }
}

/deep/ .el-checkbox__label {
  display: none;
}

.center {
  padding-left: 30px;
}

.mr {
  margin-right: 3px;
}

.footer_box {
  width: 200px;
  height: 200px;
  // border: 1px solid red;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 100px;

  .storeImg {
    width: 130px;
    height: 85px;
    // border: 1px solid #e3e;
    margin-left: 35px;
    background: url('~@/assets/icon/store.png');

    .el-icon-shopping-cart-full {
      font-size: 65px;
      color: rgba(153, 153, 153, 0.2);
    }

  }

  .bg {
    width: 50px;
    height: 11px;
    background: rgba(51, 51, 51, 0.15);
    filter: blur(5px);
    margin-left: 35%;

  }

  .prompt {
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    line-height: 20px;
    margin-top: 20px;
  }

  .addStore {
    width: 150px;
    height: 40px;
    background: #FA4A14;
    box-shadow: 0 0 10px 0 #F93A00;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 25px;
    margin-top: 50px;
    margin-left: 20px;
    text-align: center;

    .el-icon-circle-plus-outline {
      font-size: 20px;

    }
  }
}

/deep/ .el-pagination {
  margin-bottom: 20px;
}

.Listbox {
  position: relative;
  height: 600px;
}

.storeName {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/deep/ .el-col {
  border: 1px solid transparent;
}

.authorize {
  color: #FA4A14;
  cursor: pointer;

}

.opacityShow {
  opacity: .4;
}
</style>

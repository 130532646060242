<template>
  <el-dialog
      title="亚马逊授权"
      :visible.sync="value"
      width="500px"
      :before-close="handleClose">
    <div v-if="amazonLoading">
      <div style="line-height: 30px">
        <div>店铺名称：{{ amazonItem.name }}</div>
        <div>亚马逊站点：
          <span v-for="(val,index) in country(amazonItem.amazon_site_country)" :key="index"> {{ val }}</span>
        </div>
        <div style="line-height: 25px;color: #f5222d;">请确保您在该店铺的常用IP环境进行操作，以避免店铺关联（建议使用VPN、紫鸟浏览器等）</div>
      </div>
      <div style="margin-top: 20px;display: flex;justify-content: flex-end">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="jumpToAmazon">跳转亚马逊授权</el-button>
      </div>
    </div>

    <authorization-loading v-else @loadingShow="loadingShow"/>
  </el-dialog>
</template>

<script>
import {amazonOauthUrl} from '@/api/shops';
import authorizationLoading from "./authorizationLoading";

export default {
  name: "amazonLicensing",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    amazonItem: {
      type: Object
    },
    allCountry: {
      type: Array
    },
    itemId: {
      type: Boolean | String
    },
    shopsList: {
      type: Array
    }
  },
  components: {
    authorizationLoading
  },
  data: () => ({
    amazonLoading: true
  }),
  mounted() {

  },
  methods: {
    handleClose() {
      this.$emit('input', false)
      this.amazonLoading = true
    },
    //   筛选站点
    country(amazon_site_country) {
      if (!!amazon_site_country) {
        let countryArr = amazon_site_country.map(Number)
        let name = []
        this.allCountry.forEach(item => {
          countryArr.forEach(itm => {
            if (item.id === itm) {
              name.push(item.name_zh)
            }
          })


        })
        return name
      }

    },

    async jumpToAmazon() {
      if (!!this.amazonItem.id) {
        try {
          const {data} = await amazonOauthUrl({storeId: this.amazonItem.id})
          window.open(data.info)
          // window.location.href = data.info;
          this.amazonLoading = false
        } catch (e) {
          this.$message.error(e?.msg)
        }
      }

    },

    async loadingShow() {
      this.handleClose()
      await this.$emit('update')
      this.amazonLoading = true
      // await this.shopsList.forEach(item => {
      //   if (item.id === this.itemId) {
      //     this.$message.warning(item.is_authorize_word)
      //   }
      // })
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">

</style>
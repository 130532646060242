<template>
  <div>
    <el-dialog
        title="关联账号"
        :visible.sync="relevanceIdShow"
        :before-close="handleClose">
        <div class="hint">
            <span class="el-icon-warning-outline"></span>
            <span>每个店铺可以关联多个账号共同管理，店铺默认由创建者本人管理</span>
        </div>
        <!-- 树形 -->
        <el-tree
        :data="data"
        show-checkbox
        :props="defaultProps"
        @node-click="handleNodeClick"
        :default-expand-all='true'
        node-key="id"
        @check-change='checkChang'
        ></el-tree>
        <!-- 底部 -->
        <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
        </span>
</el-dialog>
  </div>
</template>

<script>
export default {
  props:{
      relevanceIdShow:{
          type:Boolean,
          default:false
      }
  },
  data () {
    return {
        data: [{
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    //   关闭弹窗
    handleClose(){
        this.$emit('close',false)
    },
    // 每一级被点击的回调
    handleNodeClick(data) {
        // console.log(data);
    },
    // 复选框被选中的状态
    checkChang(data,ishow){
        console.log(data);
        console.log(ishow);
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog__title {
    font-size: 18px;
    font-weight: 600;
    color: #3A3A3A;
    line-height: 25px;
}
.hint {
    width: 458px;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: rgba(250, 74, 20, 0.1);
    line-height: 30px;
}
/deep/.el-dialog__body {
    height: 410px !important;
    padding: 0 20px 20px;
    border-top: 1px solid #D8D8D8;
}
.el-icon-warning-outline {
    color:#FA4A14;
    font-size: 20px;
    margin-right: 10px;
    line-height: 30px;
    margin-left: 10px;
}
/deep/.el-dialog {
    width: 500px !important;
    height: 540px !important;
}
/deep/.el-dialog__footer {
    border-top: 1px solid #D8D8D8;
    padding-top: 25px;
}
</style>

<template>
  <div>
    <el-dialog
        title="绑定物流渠道"
        :visible.sync="transportIsShow"
        width="30%"
        @open="open"
        :before-close="handleClose">
      <div style="margin-bottom: 10px;color: #f5222d">打开自动付款功能，需绑定一个默认物流渠道</div>
      <el-form size="mini" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="物流渠道" prop="logistics">
          <el-select v-model="ruleForm.logistics" placeholder="请选择物流">
            <el-option :label="item.transport_name_cn" :value="item.transport_code_v2" v-for="item in logisticsList"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="bindDefaultLogistics">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getLogisticsWithPriceList, bindDefaultLogistics} from '@/api/shops';

export default {
  props: {
    transportIsShow: {
      type: Boolean,
      default: false
    },
    logisticsId: {
      type: Number | String
    },
    defaultLogistics: {
      type: String
    }
  },
  data() {
    return {
      ruleForm: {
        logistics: '',
      },
      rules: {
        logistics: [
          {required: true, message: '请选择物流', trigger: 'blur'},
        ]
      },
      logisticsList: []
    }
  },
  created() {

  },
  mounted() {

    // this.getLogisticsWithPriceList()
  },
  methods: {
    open() {
      console.log(this.defaultLogistics)
      if (!!this.defaultLogistics) {
        this.ruleForm.logistics = this.defaultLogistics
      }
    },
    handleClose() {
      this.$emit('close')
      this.ruleForm.logistics = ''
      this.$refs.ruleForm.resetFields();
    },
    bindDefaultLogistics() {
      this.$refs.ruleForm.validate(async val => {
        console.log(this.ruleForm.logistics)
        console.log(val)
        if (val) {
          try {
            const {msg} = await bindDefaultLogistics({
              default_logistics: this.ruleForm.logistics,
              storeId: this.logisticsId
            })
            this.$message.success(msg)
            this.$emit('update')
            this.handleClose()
          } catch (e) {
            this.$message.error(e?.msg)
          }
        }
      })


    },
    //获取物流
    async getLogisticsWithPriceList() {
      try {
        const data = await getLogisticsWithPriceList({
          country: 'US',
          weight: 100,
          factoryCode:'202108281112471114780'
        })
        this.logisticsList = data?.data?.list
      } catch (e) {
        this.$message.error(e?.msg)
      }
    },
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog__header {
  border-bottom: 1px solid #D8D8D8;
}

/deep/ .el-dialog__title {
  font-weight: 600;
  color: #333333;
  font-size: 18px;
}

/deep/ .el-input__inner {
  width: 400px !important;
}

/deep/ .el-form {
  border-bottom: none !important;
}
</style>

<template>
  <div
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#fff"
  >
    <!-- 提示语 -->
    <!--    <div class="border"></div>-->
    <!--    <div class="Hint"><i class="el-icon-warning-outline"></i> 店铺代号提交后不可更改，请填写真实信息！</div>-->
    <!-- 表单 -->
    <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
      <!--   <el-tabs v-model="activeName" :before-leave='beforeLeave' >-->

      <!--    <el-tab-pane label="店铺编辑" name="first" :disabled="!disabledShow">-->
      <el-form-item label="店铺名称" prop="name">
        <el-input v-model="form.name" placeholder="店铺名称最多不超过30个字符"></el-input>
      </el-form-item>
      <!--      <el-form-item label="店铺代号" prop="code">-->
      <!--        <el-input v-model="form.code" :disabled="!!shopsId" placeholder="不能小于6个英文、数字"></el-input>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="亚马逊站点" prop="amazon_site_area">-->
      <!--        <el-select v-model="form.amazon_site_area" placeholder="请选择站点" @change="amazonSiteChange">-->
      <!--          <el-option :label="item.name_zh" :value="item.id" v-for="item in amazonRegionList" :key="item.id"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item prop="amazon_site_country" style="margin-left: 100px" v-if="!!children.length">-->
      <!--        <el-checkbox-group v-model="form.amazon_site_country">-->
      <!--          <el-checkbox v-for="item in children" :key="item.id" :label="item.id">{{ item.name_zh }}</el-checkbox>-->
      <!--        </el-checkbox-group>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="自动付款" prop="automaticDeduction">-->
      <!--        <el-switch-->
      <!--            v-model="form.automaticDeduction"-->
      <!--            @change="changeLogistics"-->
      <!--        >-->
      <!--        </el-switch>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="自动同步订单" prop="is_autosync_order">-->
      <!--        <el-switch v-model="form.is_autosync_order"></el-switch>-->
      <!--      </el-form-item>-->
      <el-form-item>
        <el-button @click="handleClose" class="cancelBtn">取 消</el-button>
        <el-button type="primary" @click="nextStep">确定</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
// 站点
import {getSite} from '@/api/shops';
//添加店铺
import {getAddShop, geteditPlatformStore, getPutShop} from '@/api/shops';
import authorizationLoading from './authorizationLoading.vue';
import {createStore} from "../../../../../api/shops";

export default {
  components: {authorizationLoading},
  props: {
    shopsId: {
      type: Number,
      default: null
    },
    shopsList: {
      type: Array,
      default: () => []
    },


  },
  data() {
    let siteChange = () => {
      return new Promise((resolve, reject) => {
        if (!this.form.amazon_site_country.length && !!this.children.length) {
          this.$message.error('请选择国家')
          reject('请选择国家')
        } else {
          resolve()
        }
      })
    }
    return {
      form: {
        name: '', // 店铺名称
        code: '', //店铺代号
        amazon_site_area: null, // 站点
        is_authorize: false, // 亚马逊授权
        logistics: null,//物流
        type: 1,  //运单号
        amazon_site_country: [], // 国家
        is_autosync_order: false, //同步订单
        is_autosync_waybill: false, //同步运单号
        automaticDeduction: false,//自动付款
      },
      amazonRegionList: [], //站点
      children: [],  //国家
      isrequired: true,
      // loadingShow:false, //授权中
      activeName: 'first',
      value: true,
      disabledShow: true,
      loading: false,
      logisticsList: [], //物流数组
      // 效验
      rules: {
        name: [
          {required: true, message: '请输入店铺名称', trigger: 'blur'},
          {min: 6, max: 30, message: '店铺名称长度6-30个字符', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入店铺代号', trigger: 'blur'},
          {min: 6, message: '长度不能小于6个字符', trigger: 'blur'},
          {pattern: /^[a-z0-9]+$/i, message: '请输入数字或英文', trigger: 'blur'}
        ],
        amazon_site_area: [{required: true, message: '请选择亚马逊站', trigger: 'change'},],
        amazon_site_country: [{type: 'array', validator: siteChange, trigger: 'blur'},],
        logistics: [{required: true, message: '请选择物流', trigger: 'change'},],
        automaticDeduction: [{required: true, message: '请选择物流', trigger: 'change'},],
        is_autosync_order: [{required: true, message: '请选择物流', trigger: 'change'},],
        is_autosync_waybill: [{required: true, message: '请选择物流', trigger: 'change'},],
      }
    }
  },
  created() {
    // 获取详情
    if (this.shopsId) {
      this.loading = true
      // this.geteditPlatformStore()
    }
  },
  async mounted() {
    //物流
    // await this.getLogisticsWithPriceList()

    // 获取站点
    // await this.getSite()
    // await this.amazonRegionList.forEach(item => {
    //   if (item.id === this.form.amazon_site_area) {
    //     this.children = item.countrys
    //   }
    // });
    // this.$emit('update')

  },
  methods: {
    changeLogistics() {
      this.$forceUpdate()
    },
    nextStep() {
      this.$refs.form.validate(async val => {
        if (val) {
          //#region
          //   if (this.shopsId) {
          //     //编辑
          //     try {
          //       //编辑保存
          //       const { msg} = await getPutShop({
          //         storeId: this.shopsId,
          //         name: this.form.name,
          //         code: this.form.code,
          //         amazon_site_area: this.form.amazon_site_area,
          //         amazon_site_country: this.form.amazon_site_country.join(','),
          //         is_auto_pay: this.form.automaticDeduction ? 1 : 0,
          //         is_autosync_order: this.form.is_autosync_order ? 1 : 0
          //       })
          //       this.$message.success(msg)
          //     } catch (e) {
          //       this.$message.error(e?.msg)
          //     }
          //
          //
          //   } else {
          //     //新增
          //     try {
          //       const {msg} = await getAddShop({
          //         name: this.form.name,
          //         code: this.form.code,
          //         amazon_site_area: this.form.amazon_site_area,
          //         amazon_site_country: this.form.amazon_site_country.join(','),
          //         is_auto_pay: this.form.automaticDeduction ? 1 : 0,
          //         is_autosync_order: this.form.is_autosync_order ? 1 : 0
          //       })
          //       this.$message.success(msg)
          //     } catch (e) {
          //       this.$message.error(e)
          //     }
          //   }
          //   this.$emit('update')
          //   this.handleClose()
          //#endregion
          try {
            const { message } = await createStore({
              storeName: this.form.name
            })
            this.$message.success(message)
            this.$emit('update')
            this.handleClose()
          } catch (e) {
            this.$message.error(e)
          }
        }
      })
    },


    //   点击取消
    handleClose() {
      this.$emit('close', false)
      this.$refs.form.resetFields()
    },
    //获取详情
    async geteditPlatformStore() {
      this.loading = true
      try {
        const {data} = await geteditPlatformStore({storeId: this.shopsId})
        this.form = data.info
        this.form.amazon_site_country = data.info.amazon_site_country.map(Number)
        this.form.type = 1
        this.form.automaticDeduction = data.info.is_auto_pay === 1
        this.form.logistics = data.info.default_logistics
        this.form.is_autosync_order = !!data?.info.is_autosync_order
        await this.amazonRegionList.forEach(item => {
          if (item.id === this.form.amazon_site_area) {
            this.children = item.countrys
          }
        });
      } catch (error) {
        const {msg} = error
        this.$message.error(msg)
      }
      //   关闭loading
      setTimeout(() => {
        this.loading = false
      }, 500)

    },
    // 站点
    async getSite() {
      try {
        const {data} = await getSite()
        data.amazonRegionList.forEach(item => {
          if (item.id === this.form.amazon_site_area) {
            this.children = item.countrys
          }
        });
        this.amazonRegionList = data.amazonRegionList
      } catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }

    },

    //亚马逊站点变化 国家跟着变化
    amazonSiteChange() {
      this.amazonRegionList.forEach(item => {
        if (item.id === this.form.amazon_site_area) {
          this.children = item.countrys
        }
      });
      this.form.amazon_site_country = []
    }
  },
  computed: {},

  watch: {}
}
</script>

<style scoped lang="less">
.border {
  width: 690px;
  height: 1px;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 5px;
}

.Hint {
  width: 690px;
  height: 30px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  text-align: center;
  background: rgba(250, 74, 20, 0.1);
  margin-bottom: 10px;

  .el-icon-warning-outline {
    font-size: 20px;
    line-height: 30px;
    margin-right: 5px;
    color: #FA4A14;
  }
}


/deep/ .el-input__inner {
  width: 450px !important;
  height: 40px !important;
}

/deep/ .el-form-item__label {
  //line-height: 40px;
  //margin-top: 10px;
  width: 150px;
}

/deep/ .el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-form {
  border-bottom: none !important;
}

.ml30 {
  margin-left: 25px;
}

.checkbox {
  width: 455px;
  height: 160px;
  // margin-left: 130px;
  word-wrap: break-word;
  word-break: normal;

  & > li {
    height: 35px;


  }
}

/deep/ .el-dialog__footer {
  padding-top: 20px !important;
}

.dd {
  height: 30px;
}

/deep/ .el-checkbox-group {
  width: 525px;
  margin-left: 55px;
}

.tabl {
  width: 50px;
  word-wrap: break-word;
}

/deep/ .el-checkbox__input:nth-of-type(4) {
  margin-bottom: 10px;
}

.cancelBtn {
  border: 1px solid #FA4A14;
  color: #FA4A14;
  margin-left: 505px;
}

/deep/ .el-dialog {
  margin-top: 5vh !important;
}

///deep/.el-input {
//    width:445px !important;
//    height: 40px;
//}
.authorizationHelp {
  margin-left: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #FA4A14;
  line-height: 20px;
  cursor: pointer;
}

.el-icon-question {
  margin-left: 19px;
  font-size: 15px;
  color: #FA4A14;
  cursor: pointer;
}

/deep/ .el-form-item__content {
  //line-height: 20px;
  width: 100%;
}

.dialog-footer {
  margin-left: 500px;
}

/deep/ .el-checkbox__label {
  display: inline-block !important;
}

.unauthorized {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}

/deep/ .el-select {
  width: 100%;
}
</style>

import request from "@/utils/request";
const prefix = '/shops'
//获取站点
export const getSite = (
  params,
  callback
) => {

    return request(`${prefix}/amazonRegionList`, { params,method: 'get'}, callback)
}
// 获取店铺列表
export const getMyPlatformList = (
  params,
  callback
) => {
    return request(`${prefix}/myPlatformList`, { params,method: 'get'}, callback)
}



export const storeIndex = (
    params,
    callback
) => {
    return request('/user/storeIndex', { params,method: 'get'}, callback)
}


// 添加店铺
export const getAddShop = (
  params,
  callback
) => {
    return request(`${prefix}/addPlatformStore`, {params, method: 'post'}, callback)
}

export const createStore = (
    data,
    callback
) => {
    return request('/user/createStore', {data, method: 'post'}, callback)
}



//编辑店铺
export const geteditPlatformStore = (
    params,
     callback
) => {
   return request(`${prefix}/infoPlatformStore`, {params , method: 'get'}, callback)
}
//禁用
export const getDisableShop = (
  data,
 callback
) => {
   return request(`${prefix}/unablePlatformStore`, {data, method: 'delete'}, callback)
}
//启用
export const getEnableShop = (
  data,
 callback
) => {
   return request(`${prefix}/enablePlatformStore`, {data, method: 'delete'}, callback)
}
//删除店铺
export const getDEleteShop = (
  data,
 callback
) => {
   return request(`${prefix}/deletePlatformStore`, {data, method: 'delete'}, callback)
}
//编辑
export const getPutShop = (
  data,
 callback
) => {
   return request(`${prefix}/editPlatformStore`, {data, method: 'put'}, callback)
}

//获取物流

export const getLogisticsWithPriceList = (
    params,
    callback
) => {

    return request(`/logistics/getLogisticsWithPriceList`, {params, method: 'get'}, callback)
}

//获取亚马逊授权地址
export const amazonOauthUrl = (
    params,
    callback
) => {

    return request(`${prefix}/amazonOauthUrl`, {params, method: 'get'}, callback)
}

//保存物流
export const bindDefaultLogistics = (
    params,
    callback
) => {

    return request(`/shops/bindDefaultLogistics`, {params, method: 'put'}, callback)
}

//启用禁用店铺

export const ableStore = (
    params,
    callback
) => {

    return request(`/user/ableStore`, {params, method: 'put'}, callback)
}
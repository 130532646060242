<template>
  <div class="box">
    <!-- 店铺头部 -->
<!--    <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini" ref="ruleForm">-->
<!--      <el-row :gutter="30">-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="5">-->
<!--          <el-form-item label="搜索店铺">-->
<!--            <el-input v-model="formInline.keywordOrCode" placeholder="搜索店铺名称"></el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="5">-->
<!--          <el-form-item label="状态">-->
<!--            <el-select v-model="formInline.state" placeholder="请选择状态" clearable>-->
<!--              <el-option v-for="(item ,index) in classifyList" :key='index' :label="item" :value="index"></el-option>-->

<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="5">-->
<!--          <el-form-item label="站点">-->
<!--            <el-select v-model="formInline.site" placeholder="请选择站点" clearable>-->
<!--              <el-option :label="item.name_zh" :value="item.id" v-for="item in allCountry" :key="item.id"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="5">-->
<!--          <el-form-item label="账号">-->
<!--            &lt;!&ndash;    <el-input v-model="formInline.user" placeholder="请输入账号"></el-input>&ndash;&gt;-->
<!--            <el-select v-model="formInline.user" placeholder="请选择账号" clearable>-->
<!--              <el-option v-for="item in allSubAccount"-->
<!--                         :key="item.id"-->
<!--                         :label="item.phone"-->
<!--                         :value="item.code"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="24" :md="14" :lg="9" :xl="6">-->
<!--          <el-form-item label="创建时间">-->
<!--            <el-date-picker-->
<!--                v-model="formInline.time"-->
<!--                type="datetimerange"-->
<!--                :picker-options="pickerOptions"-->
<!--                range-separator="至"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期"-->
<!--                align="right">-->
<!--            </el-date-picker>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="5">-->
<!--          <el-form-item label-width="80px">-->
<!--            <el-button type="primary" class="Submit" @click="onSubmit">查询</el-button>-->
<!--            <el-button class="reset" @click="onReset">重置</el-button>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--    </el-form>-->
    <!-- 添加按钮 -->
    <div class="allBtn">
      <button class="addStore" @click="addStore">添加店铺</button>
    </div>
    <!-- 店铺列表 -->
    <store-list ref="storeList"
                @NOactive='NOactive'
                @active='active'
                @addStore='addStore'
                :allCountry='allCountry'
                :formInline='formInline'
                :countrys='countrys'
    />
    <el-dialog
        :title="title"
        :visible.sync="authorizationIsShow"
        :before-close="handleClose"
    >
      <!-- 添加店铺 -->
      <compile-and-authorization
          @updateId='updateId'
          :oneId='oneId'
          v-if="authorizationIsShow"
          @update='onSubmit'
          @close='authorizationIsShow=$event'
      />

    </el-dialog>

  </div>
</template>

<script>

// 店铺
import {getSite, getMyPlatformList} from '@/api/shops';
// <!-- 添加店铺 -->
import CompileAndAuthorization from './components/compileAndAuthorization.vue';
//店铺列表
import storeList from './components/storeList.vue';
import {getASubAccount} from "@/api/login";

export default {
  components: {storeList, CompileAndAuthorization},
  data() {
    return {
      allSubAccount: null, //子账号
      oneId: null,
      activeColor: false, // 颜色
      allCountry: [], //全部国家
      authorizationIsShow: false, //添加店铺弹窗状态
      shopList: [], //筛选的数组
      countrys: [], // 站点
      title: '添加店铺',
      shopTitle: false, // 表头复选框
      formInline: {
        keywordOrCode: '', //关键字
        user: '',  // 账号
        site: '',  // 站点
        state: '',// 状态
        time: '', //创建时间
        perPage: 5, //每页显示多少
        page: 1, //页码
      },
      // 超过当前时间禁选
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      // 状态
      classifyList: [],


    }
  },
  created() {

  },
  mounted() {
    // this.list()
    // this.getSite()
    // 获取子账号
    // this.getASubAccount()
  },

  methods: {

    //获取子账号
    async getASubAccount() {
      try {
        const data = await getASubAccount()

        this.allSubAccount = data?.data

      } catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
    },
    updateId() {
      this.list()
    },
    // 开启弹窗
    addStore() {
      this.authorizationIsShow = true
    },
    // 关闭弹窗
    handleClose() {
      this.authorizationIsShow = false
    },
    // 点击查询
    onSubmit() {
      !!this.formInline.time ? this.formInline.time : this.formInline.time = []
      this.$refs.storeList.getSerach()

    },
    // 点击重置
    async onReset() {
      this.formInline = {
        keywordOrCode: '', //关键字
        user: '',  // 账号
        site: '',  // 站点
        state: '',// 状态
        time: '', //创建时间
        department: '', //部门
        perPage: 10, //每页显示多少
        page: 1, //页码
      }
      await this.$refs.ruleForm.resetFields()
      await this.$refs.storeList.getSerach()
    },

    //站点
    async getSite() {
      try {
        const {data} = await getSite()
        this.countrys = data.amazonRegionList

        // 状态
        this.classifyList = data.authorizeInfoList

        //全部国家
        this.countrys.forEach(item => {
          this.allCountry.push(...item.countrys)
        })

      } catch (error) {
        const {msg} = error
        this.$message.error(msg)
      }
    },
    active() {
      this.activeColor = true
    },
    NOactive() {
      this.activeColor = false
    },

    async list() {
      const {data} = await getMyPlatformList({
        perPage: 10,
        page: 1,
      })
      this.oneId = data?.list[0]?.id
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  margin-top: 5vh !important;
}

/deep/ .el-dialog {
  width: 730px;
  // height: 797px;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/deep/ .el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: #3A3A3A;
  line-height: 25px;
}

/deep/ .el-dialog__header {
  border-bottom: none;
  padding-bottom: 20px;
}

/deep/ .el-input__inner {
  width: 100% !important;
}

/deep/ .el-form-item {
  display: flex;
  margin-right: 10px;
}

/deep/ .el-form-item__label {
  //font-size: 14px;
  //font-weight: 400;
  //color: #333333;
  //line-height: 20px;
}

.reset {
  font-size: 12px !important;
  width: 60px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid #FA4A14;
  color: #FA4A14;
}

/deep/ .el-date-editor {
  width: 350px;
  margin-right: 11px;
}

/deep/ .el-form {
  // width:1650px ;
  margin-bottom: 10px;
  border-bottom: 1px solid #E9E9E9;
}

.addStore {
  width: 80px;
  height: 27px;
  background: #FA4A14;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}

.logistics {
  width: 136px;
  height: 27px;
  background: #F0F0F0;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-left: 10px;
}

.allBtn {
  margin-top: 20px;
}

.box {
  height: 750px;
}

/deep/ .gallery-foot {
  bottom: 20px;
}

/deep/ .el-input__inner {
  height: 26px;
  line-height: 26px;
}

/deep/ .el-dialog__body {
  padding: 30px 20px 20px !important;

}

.activebg {
  color: #fff;
  background: #FA4A14;
}

/deep/ .el-form-item__content {
  flex: 1;
  width: 100%;
}

/deep/ .el-input el-input--mini {
  width: 100%;
}

/deep/ .el-select--mini {
  width: 100%;
}
</style>
